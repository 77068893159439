import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";
import { Text } from "../Text";
import { pxToRem } from "../../utils/px-to-rem";
import { EncNavPath } from "../../assets/misc-svgs/components";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { useElementSize } from "usehooks-ts";
import { useWhyDidYouUpdate } from "ahooks";
import { lighten, rgba } from "polished";

function EnvNavPathInner({
    useProps,
    ...props
}: React.SVGProps<SVGSVGElement> & {
    useProps: React.SVGProps<SVGUseElement>;
}) {
    return (
        <svg
            viewBox="0 0 807 807"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <defs>
                <clipPath id="env-nav-path-inner_svg__b">
                    <use xlinkHref="#env-nav-path-inner_svg__a" />
                </clipPath>
                <path
                    id="env-nav-path-inner_svg__a"
                    d="M.5 403.5a403 403 0 10806 0 403 403 0 10-806 0"
                />
            </defs>
            <use
                {...useProps}
                className="main-path"
                xlinkHref="#env-nav-path-inner_svg__a"
                // strokeWidth={100}
                clipPath="url(#env-nav-path-inner_svg__b)"
            />
        </svg>
    );
}

function reverseString(str) {
    return str.split("").reverse().join("");
}

const REVERSE_JHURRA_TEXT = (
    <>
        {reverseString("rasgulla")}&nbsp;{reverseString("ka")}&nbsp;
        {reverseString("jhurre")}
    </>
);
const JHURRA_TEXT = <>Jhurre&nbsp;Ka&nbsp;Rasgulla</>;

export const NAV_ITEMS: INavItem[] = [
    {
        name: "ledikeni",
        imageUrl: "/images/nav-ledikeni.png",
        reverseText: <>{reverseString("ledikeni")}</>,
        text: <>ledikeni</>,
        direction: "clockwise"
    },
    {
        name: "jhurra",
        imageUrl: "/images/nav-jhurra.png",
        text: JHURRA_TEXT,
        reverseText: REVERSE_JHURRA_TEXT,
        direction: "anticlockwise"
    },
    {
        name: "kalajam",
        imageUrl: "/images/nav-kalajam.png",
        text: <>Kala&nbsp;&nbsp;Jam</>,
        reverseText: (
            <>
                {reverseString("jam")}&nbsp;&nbsp;{reverseString("kala")}
            </>
        ),
        direction: "clockwise"
    },
    {
        name: "gulabjamun",
        imageUrl: "/images/nav-jhurra.png",
        text: <>Gulab&nbsp;Jamun</>,
        reverseText: (
            <>
                {reverseString("jamun")}&nbsp;{reverseString("gulab")}
            </>
        ),
        direction: "anticlockwise"
    },
    {
        name: "pantua",
        imageUrl: "/images/nav-jhurra.png",
        text: "Pantua",
        reverseText: reverseString("pantua"),
        direction: "clockwise"
    },
    {
        name: "rasgulla",
        imageUrl: "/images/nav-jhurra.png",
        text: "Rasgulla",
        reverseText: reverseString("rasgulla"),
        direction: "anticlockwise"
    }
];

export interface INavItem {
    name?: string;
    imageUrl: string;
    text: string | JSX.Element;
    direction: "clockwise" | "anticlockwise";
    reverseText: string | JSX.Element;
}

export const EncyclopediaNavItemCircle = ({
    imageUrl,
    text,
    direction,
    reverseText,
    diameter = 500,
    className,
    duration: TOTAL_DURATION = 50,
    enabled,
    onClick,
    index
}: INavItem & {
    onClick: () => void;
    diameter?: number;
    className?: string;
    duration?: number;
    enabled?: boolean;
    index: number;
}) => {
    const mainRef = useRef(null);
    const [remainingSpace, setRemainingSpace] = useState(0);
    const [maxNoOfItems, setMaxNoOfItems] = useState(1);
    const [mainTween, setMainTween] = useState<GSAPTween | null>(null);
    const circumference = Math.PI * diameter;
    gsap.registerPlugin(MotionPathPlugin);
    const [sampleRef, { width: sampleRefWidth }] = useElementSize();

    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        if (mainTween) {
            if (enabled) {
                mainTween.play();
            } else mainTween.pause();
        }
    }, [enabled]);

    useEffect(() => {
        const ctx = gsap.context(() => {
            Promise.all(
                Array.from(gsap.utils.toArray<HTMLImageElement>("img"))
                    .filter((img) => !img.complete)
                    .map(
                        (img) =>
                            new Promise((resolve) => {
                                img.onload = img.onerror = resolve;
                            })
                    )
            ).then(() => {
                setImagesLoaded(true);
            });
        }, mainRef); // <- IMPORTANT! Scopes selector text

        return () => ctx.revert();
    }, []);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            if (imagesLoaded) {
                const itemLength = sampleRefWidth;
                setMaxNoOfItems(Math.floor(circumference / itemLength));
                setRemainingSpace(
                    circumference -
                        Math.floor(circumference / itemLength) * itemLength
                );
            }
        }, mainRef); // <- IMPORTANT! Scopes selector text

        return () => ctx.revert();
    }, [imagesLoaded]);

    useEffect(() => {}, [maxNoOfItems]);
    useEffect(() => {
        // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
        const ctx = gsap.context(() => {
            if (imagesLoaded) {
                if (maxNoOfItems > 1) {
                    const splitArray = gsap.utils
                        .toArray<HTMLElement>(".text-list .enc-nav-item")
                        .map((el, idx) => {
                            const split = new SplitText(el, {
                                type: "chars,words",
                                charsClass: "char",
                                wordsClass: "word",
                                linesClass: "line"
                                // reduceWhiteSpace: false
                            });
                            return [
                                el.querySelector(".enc-image-wrapper"),
                                split.chars
                            ];
                        });

                    const tween = gsap.to(splitArray, {
                        paused: true,
                        reversed: direction === "anticlockwise",
                        immediateRender: true,
                        motionPath: {
                            path: "svg path",
                            align: "svg path",
                            alignOrigin: [0.5, 0.5],
                            autoRotate: true
                        },
                        stagger: {
                            each:
                                TOTAL_DURATION /
                                splitArray.reduce((acc, item) => {
                                    // @ts-ignore
                                    return acc + 1 + item[1].length;
                                }, 0),
                            repeat: -1,
                            ease: "none"
                        },
                        // transformOrigin: "50% 50%",
                        duration: TOTAL_DURATION,
                        ease: "none"
                    });

                    tween.seek(TOTAL_DURATION);
                    gsap.to(".contents", { autoAlpha: 1 });

                    setMainTween(tween);
                }
            }
        }, mainRef); // <- IMPORTANT! Scopes selector text

        return () => ctx.revert(); // cleanup
    }, [mainRef, imagesLoaded, maxNoOfItems]);

    useLayoutEffect(() => {
        mainRef.current
            .querySelector(".svg-wrapper-clicker path")
            .addEventListener("click", () => {
                onClick();
            });

        return () => {
            mainRef.current
                .querySelector(".svg-wrapper-clicker path")
                .removeEventListener("click", () => {
                    onClick();
                });
        };
    }, []);

    const strokeWidth = {
        "1": 1,
        "2": 2,
        "3": 4,
        "4": 6,
        "5": 8,
        "6": 100
    };

    return (
        <div
            className={className}
            css={{
                ".svg-wrapper, .svg-wrapper-clicker": {
                    width: pxToRem(diameter),
                    height: pxToRem(diameter)
                },
                ".svg-wrapper-clicker": {
                    position: "absolute",
                    transform: `scale(1.1)`,
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    zIndex: 202,
                    borderRadius: "50%",
                    overflow: "hidden"
                },
                ".svg-wrapper path": {
                    position: "relative",
                    opacity: 0
                },
                ".svg-wrapper-clicker .main-path, .svg-wrapper-clicker path": {
                    strokeWidth: pxToRem(strokeWidth[index]),
                    fill: "transparent",
                    opacity: 1,
                    // stroke: `${rgba("red", 0.5)}`,
                    stroke: "transparent",
                    cursor: "pointer"
                },
                ".svg-wrapper-clicker *": {
                    strokeWidth: pxToRem(100)
                }
            }}
            ref={mainRef}
        >
            <div className="contents" css={{ opacity: 0 }}>
                <EncyclopediaNavItem
                    onClick={onClick}
                    reverseText={reverseText}
                    direction={direction}
                    ref={sampleRef}
                    text={text}
                    imageUrl={imageUrl}
                    css={{ position: "absolute", opacity: 0 }}
                    className="sample-enc-nav-item enc-nav-item"
                />
                <div className="svg-wrapper">
                    <EncNavPath />
                </div>
                <div className="svg-wrapper-clicker" data-id={index}>
                    <EnvNavPathInner
                        useProps={{
                            onClick: onClick
                        }}
                    />
                </div>
                <div
                    className="text-list"
                    css={{
                        display: "grid",
                        overflow: "visible",
                        gridAutoFlow: "column",
                        width: pxToRem(circumference),
                        gridGap: remainingSpace / maxNoOfItems + "px"
                    }}
                >
                    {[...Array(maxNoOfItems).keys()].map((_, idx) => (
                        <EncyclopediaNavItem
                            // onClick={onClick}
                            reverseText={reverseText}
                            direction={direction}
                            key={idx}
                            text={text}
                            imageUrl={imageUrl}
                            className="enc-nav-item"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

const EncyclopediaNavItem = React.forwardRef<
    HTMLDivElement,
    { className?: string; onClick?: () => void } & INavItem
>(({ className, onClick, text, reverseText, imageUrl, direction }, ref) => {
    return (
        <div
            css={{
                display: "inline-grid",
                gridAutoFlow: "column",
                alignItems: "center",
                justifyContent: "start",
                ".enc-image-wrapper": {
                    paddingLeft: pxToRem(10),
                    paddingRight: pxToRem(10),
                    width: pxToRem(60)
                },
                ".char": {
                    willChange: "transform"
                },
                "*": {
                    willChange: "transform"
                }
            }}
            className={className}
            ref={ref}
        >
            <Text
                className="gj-enc-text"
                color="white"
                variant="encyclopediaNav"
                css={{
                    display: "inline-grid",
                    gridAutoFlow: "column",
                    alignItems: "center",
                    justifyContent: "start",
                    ".enc-image-wrapper": {
                        width: pxToRem(60)
                    }
                }}
            >
                <span css={{ visibility: "hidden" }}>Z</span>
                {direction === "clockwise" ? reverseText : text}
                <span css={{ visibility: "hidden" }}>Z</span>
                <div className="enc-image-wrapper">
                    <img
                        css={{ width: "100%" }}
                        className="enc-image-fake-char"
                        src={imageUrl}
                    />
                </div>
            </Text>
        </div>
    );
});

export interface IEncyclopediaNav {
    item1: INavItem;
    item2: INavItem;
    item3: INavItem;
    item4: INavItem;
    item5: INavItem;
    item6: INavItem;
    item1OnClick?: () => void;
    item2OnClick?: () => void;
    item3OnClick?: () => void;
    item4OnClick?: () => void;
    item5OnClick?: () => void;
    item6OnClick?: () => void;
}

export const EncyclopediaNav = ({
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
    item1OnClick,
    item2OnClick,
    item3OnClick,
    item4OnClick,
    item5OnClick,
    item6OnClick
}: IEncyclopediaNav) => {
    const OUTER_DIAMETER = 900;
    const ITEM_1_DIAMETER = 800;
    const ITEM_2_DIAMETER = 670;
    const ITEM_3_DIAMETER = 560;
    const ITEM_4_DIAMETER = 450;
    const ITEM_5_DIAMETER = 340;
    const ITEM_6_DIAMETER = 220;

    const getCSSPropsForItem = (ITEM_DIAMETER) => {
        const side = (OUTER_DIAMETER - ITEM_DIAMETER) / 2;
        return { left: pxToRem(side), top: pxToRem(side) };
    };
    const [isActive, setIsActive] = useState(false);
    const [mainTL, setMainTL] = useState<GSAPTimeline | null>(null);
    const mainRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            setMainTL(
                gsap
                    .timeline({
                        paused: true,
                        defaults: {
                            duration: 1,
                            ease: "power2.out"
                        }
                    })
                    .to(".nav-centre", {
                        // filter: "drop-shadow(0px 0px 171.677px rgba(252, 252, 252, 0.4))",
                        scale: 2
                    })
                    .fromTo(
                        ".rings-bg",
                        { autoAlpha: 0, scale: 0.5 },
                        {
                            autoAlpha: 1,
                            scale: 1
                        },
                        "="
                    )
                    .from(
                        ".rings",
                        {
                            scale: 0.1,
                            autoAlpha: 0
                            // stagger: {
                            //   each: 0.2,
                            // },
                        },
                        "="
                    )
            );
        }, mainRef); // <- IMPORTANT! Scopes selector text

        return () => ctx.revert();
    }, []);

    const onMouseEnter = () => {
        setIsOpen(true);
        mainTL && mainTL.timeScale(1).play();
    };

    const onMouseLeave = () => {
        mainTL &&
            mainTL
                .timeScale(2)
                .reverse()
                .then(() => {
                    setIsOpen(false);
                });
    };
    return (
        <div
            ref={mainRef}
            css={{
                position: "fixed",
                right: pxToRem(-OUTER_DIAMETER / 2 + 50),
                top: pxToRem(-OUTER_DIAMETER / 2 + 50)
            }}
        >
            <div
                css={{
                    position: "relative",
                    width: pxToRem(OUTER_DIAMETER),
                    height: pxToRem(OUTER_DIAMETER),
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
                onMouseLeave={onMouseLeave}
            >
                <div
                    className="rings-bg"
                    css={{
                        position: "absolute",
                        top: "0",
                        bottom: 0,
                        right: "0%",
                        left: 0,
                        zIndex: -1,
                        filter: "blur(20px)",
                        transform: "scale(1.1)",
                        transformOrigin: "right top",
                        background: rgba(lighten(0.03,"#3C231D"), 0.95),
                        borderRadius: "50%",
                        willChange: "transform"
                    }}
                />
                <div
                    className="nav-centre-wrapper"
                    css={{
                        zIndex: 205,
                        position: "relative",
                        cursor: "default"
                    }}
                >
                    <div
                        className="nav-centre"
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                        onMouseEnter={onMouseEnter}
                        css={{
                            cursor: "pointer",
                            transformOrigin: "center",
                            width: pxToRem(40),
                            filter: `drop-shadow(0px 0px ${pxToRem(
                                26
                            )} rgba(252, 252, 252, 0.6))`,
                            willChange: "transform"
                        }}
                    >
                        <img
                            css={{ width: "100%" }}
                            src="/images/rasgulla-sun-planet.png"
                        />
                    </div>
                </div>
                <div
                    className="rings"
                    css={{
                        transformOrigin: "center",
                        zIndex: 1,
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        willChange: "transform"
                    }}
                >
                    <EncyclopediaNavItemCircle
                        index={1}
                        onClick={() => {
                            onMouseLeave();
                            item1OnClick && item1OnClick();
                        }}
                        enabled={isOpen}
                        className="circle-item"
                        css={{
                            position: "absolute",
                            ...getCSSPropsForItem(ITEM_1_DIAMETER)
                        }}
                        {...item1}
                        diameter={ITEM_1_DIAMETER}
                        duration={140}
                    />
                    <EncyclopediaNavItemCircle
                        index={2}
                        onClick={() => {
                            onMouseLeave();
                            item2OnClick && item2OnClick();
                        }}
                        enabled={isOpen}
                        className="circle-item"
                        css={{
                            position: "absolute",
                            ...getCSSPropsForItem(ITEM_2_DIAMETER)
                        }}
                        {...item2}
                        diameter={ITEM_2_DIAMETER}
                        duration={180}
                    />
                    <EncyclopediaNavItemCircle
                        index={3}
                        onClick={() => {
                            onMouseLeave();
                            item3OnClick && item3OnClick();
                        }}
                        enabled={isOpen}
                        className="circle-item"
                        css={{
                            position: "absolute",
                            ...getCSSPropsForItem(ITEM_3_DIAMETER)
                        }}
                        {...item3}
                        diameter={ITEM_3_DIAMETER}
                        duration={190}
                    />
                    <EncyclopediaNavItemCircle
                        index={4}
                        onClick={() => {
                            onMouseLeave();
                            item4OnClick && item4OnClick();
                        }}
                        enabled={isOpen}
                        className="circle-item"
                        css={{
                            position: "absolute",
                            ...getCSSPropsForItem(ITEM_4_DIAMETER)
                        }}
                        {...item4}
                        diameter={ITEM_4_DIAMETER}
                        duration={210}
                    />
                    <EncyclopediaNavItemCircle
                        index={5}
                        onClick={() => {
                            onMouseLeave();
                            item5OnClick && item5OnClick();
                        }}
                        enabled={isOpen}
                        className="circle-item"
                        css={{
                            position: "absolute",
                            ...getCSSPropsForItem(ITEM_5_DIAMETER)
                        }}
                        {...item5}
                        diameter={ITEM_5_DIAMETER}
                        duration={200}
                    />
                    <EncyclopediaNavItemCircle
                        index={6}
                        onClick={() => {
                            onMouseLeave();
                            item6OnClick && item6OnClick();
                        }}
                        enabled={isOpen}
                        className="circle-item"
                        css={{
                            position: "absolute",
                            ...getCSSPropsForItem(ITEM_6_DIAMETER)
                        }}
                        {...item6}
                        diameter={ITEM_6_DIAMETER}
                        duration={200}
                    />
                </div>
            </div>
        </div>
    );
};
